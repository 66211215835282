// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppPage-module__mainLayout__y9neO {
  height: calc(100vh - 42px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/AppPage.module.less"],"names":[],"mappings":"AAEA;EACE,0BAAA;AADF","sourcesContent":["// Application Page Styles (Applies to all pages)\n\n.mainLayout {\n  height: calc(100vh - 42px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainLayout": `AppPage-module__mainLayout__y9neO`
};
export default ___CSS_LOADER_EXPORT___;
